import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../Form.css';
import { useNavigate, Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext.js';
import { useBackend } from './BackendContext.js';
import ReactGA from 'react-ga4';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function Register() {
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const backend = useBackend()
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  useEffect(() => {
    let navigationTimer;

    if (message === 'Sign up successful') {
      navigationTimer = setTimeout(() => {
        navigate('/confirm-email');
      }, 1250);
    }

    return () => {
      if (navigationTimer) {
        clearTimeout(navigationTimer);
      }
    };
  }, [message, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');

    if (email !== confirmEmail) {
      setMessage('Emails do not match.');
      setIsLoading(false);
      return;
    }
    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      setIsLoading(false);
      return;
    }

    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      setIsLoading(false);
      return;
    }

    ReactGA.event({ category: 'Register', action: 'submit_register' });

    try {
      const recaptchaToken = await executeRecaptcha('signup');
      const response = await axios.post(`${backend}/api/auth/signup`, {
        email,
        password,
        recaptchaToken
      });
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Error: ' + (error.response?.data?.error || 'An error occurred'));
      console.error('Error registering:', error);
      ReactGA.event({ category: 'Register', action: 'exception', label: error.response?.status });
    } finally {
      setIsLoading(false);
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/profile" replace />;
  }


  return (
    <div className='container'>
      <div className='col'>
        <h2>Register</h2>
        <form onSubmit={handleSubmit}>
          <div className='row'>
            <label>Email:</label>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </div>
          <div className='row'>
            <label>Confirm Email:</label>
            <input type="email" value={confirmEmail} onChange={(e) => setConfirmEmail(e.target.value)} required />
          </div>
          <div className='row'>
            <label>Password:</label>
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
          </div>
          <div className='row'>
            <label>Confirm Password:</label>
            <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
          </div>
          <div className='row'>
            <button className='loginButton' type="submit" disabled={isLoading}>
              {isLoading ? 'Registering...' : 'Register'}
            </button>
          </div>
        </form>
        <p>{message}</p>
      </div>
    </div>
  );
}

export default Register;