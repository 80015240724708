import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import { useBackend } from './BackendContext';
import ReactGA from 'react-ga4';

const AdminPaymentPanel = () => {
    const [paymentIntentId, setPaymentIntentId] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [paymentDetails, setPaymentDetails] = useState(null);
    const { accessToken } = useAuth();
    const backend = useBackend();

    const handlePaymentIntentLookup = async () => {
        if (!paymentIntentId.trim()) {
            setError('Payment Intent ID is required');
            return;
        }

        setLoading(true);
        setError('');
        setMessage('');
        setPaymentDetails(null);

        try {
            const response = await axios.get(
                `${backend}/api/admin/payments/intent/${paymentIntentId}`,
                { headers: { Authorization: `Bearer ${accessToken}` } }
            );

            setPaymentDetails(response.data.paymentIntent);
            ReactGA.event({
                category: 'Admin',
                action: 'payment_intent_lookup',
                label: response.data.paymentIntent.status
            });
        } catch (err) {
            setError(err.response?.data?.message || 'Failed to lookup payment intent');
            ReactGA.event({
                category: 'Admin Payment Lookup',
                action: 'exception',
                label: err.message
            });
        } finally {
            setLoading(false);
        }
    };

    const handleCancelPaymentIntent = async () => {
        if (!paymentIntentId.trim()) {
            setError('Payment Intent ID is required');
            return;
        }

        if (!window.confirm('Are you sure you want to cancel this payment intent?')) {
            return;
        }

        setLoading(true);
        setError('');
        setMessage('');

        try {
            const response = await axios.post(
                `${backend}/api/admin/payments/cancel-intent`,
                { paymentIntentId },
                { headers: { Authorization: `Bearer ${accessToken}` } }
            );

            setMessage(response.data.message);
            setPaymentDetails(null);
            ReactGA.event({
                category: 'Admin',
                action: 'cancel_payment_intent',
                label: 'success'
            });
        } catch (err) {
            setError(err.response?.data?.message || 'Failed to cancel payment intent');
            ReactGA.event({
                category: 'Admin Cancel Payment',
                action: 'exception',
                label: err.message
            });
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (timestamp) => {
        return new Date(timestamp * 1000).toLocaleString();
    };

    const formatAmount = (amount) => {
        return (amount / 100).toFixed(2);
    };

    return (
        <div className="admin-payment-panel">
            <h3 className="text-center mb-4">Payment Intent Management</h3>

            <div className="card">
                <div className="card-body">
                    <div className="mb-3">
                        <label htmlFor="paymentIntentId" className="form-label">Payment Intent ID</label>
                        <div className="input-group">
                            <input
                                type="text"
                                id="paymentIntentId"
                                className="form-control"
                                value={paymentIntentId}
                                onChange={(e) => setPaymentIntentId(e.target.value)}
                                placeholder="pi_..."
                                disabled={loading}
                            />
                            <button
                                className="btn btn-outline-primary"
                                onClick={handlePaymentIntentLookup}
                                disabled={loading}
                            >
                                Lookup
                            </button>
                        </div>
                    </div>

                    {paymentDetails && (
                        <div className="mt-4">
                            <h4>Payment Intent Details</h4>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <th>ID:</th>
                                        <td>{paymentDetails.id}</td>
                                    </tr>
                                    <tr>
                                        <th>Status:</th>
                                        <td>
                                            <span className={`badge ${paymentDetails.status === 'succeeded' ? 'bg-success' :
                                                    paymentDetails.status === 'canceled' ? 'bg-danger' :
                                                        'bg-warning'
                                                }`}>
                                                {paymentDetails.status}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Amount:</th>
                                        <td>${formatAmount(paymentDetails.amount)}</td>
                                    </tr>
                                    <tr>
                                        <th>Created:</th>
                                        <td>{formatDate(paymentDetails.created)}</td>
                                    </tr>
                                </tbody>
                            </table>

                            {paymentDetails.status !== 'canceled' && (
                                <button
                                    className="btn btn-danger"
                                    onClick={handleCancelPaymentIntent}
                                    disabled={loading}
                                >
                                    Cancel Payment Intent
                                </button>
                            )}
                        </div>
                    )}

                    {message && (
                        <div className="alert alert-success mt-3">
                            {message}
                        </div>
                    )}

                    {error && (
                        <div className="alert alert-danger mt-3">
                            {error}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdminPaymentPanel;