import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import { useBackend } from './BackendContext';
import ReactGA from 'react-ga4';
import AddProductForm from './AddProductForm';

const AdminProductPanel = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showAddForm, setShowAddForm] = useState(false);
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const { accessToken } = useAuth();
  const backend = useBackend();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  const fetchProducts = useCallback(async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get(`${backend}/api/admin/products`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      setProducts(response.data);
    } catch (err) {
      console.error('Error fetching products:', err);
      setError('Failed to fetch products. Please try again later.');
      ReactGA.event({ category: 'Admin Fetch Products', action: 'exception' });
    } finally {
      setLoading(false);
    }
  }, [backend, accessToken]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const handleStockChange = useCallback(async (productId, newStock) => {
    try {
      if (newStock < 0 || isNaN(newStock)) {
        return;
      }
      await axios.patch(`${backend}/api/products/${productId}/stock`,
        { stock: newStock },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setProducts(prevProducts => prevProducts.map(product =>
        product.id === productId ? { ...product, stock: newStock } : product
      ));
      ReactGA.event({ category: 'Admin', action: 'update_product_stock' });
    } catch (error) {
      console.error('Error updating product stock:', error);
      setError('Failed to update product stock. Please try again.');
      ReactGA.event({ category: 'Admin Update Product Stock', action: 'exception' });
    }
  }, [backend, accessToken]);

  const handlePriceChange = useCallback(async (productId, newPrice) => {
    try {
      if (newPrice < 0 || isNaN(newPrice)) {
        return;
      }
      await axios.patch(`${backend}/api/products/${productId}/price`,
        { price: newPrice },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setProducts(prevProducts => prevProducts.map(product =>
        product.id === productId ? { ...product, price: newPrice } : product
      ));
      ReactGA.event({ category: 'Admin', action: 'update_product_price' });
    } catch (error) {
      console.error('Error updating product price:', error);
      setError('Failed to update product price. Please try again.');
      ReactGA.event({ category: 'Admin Update Product Price', action: 'exception' });
    }
  }, [backend, accessToken]);

  const handleActiveToggle = useCallback(async (productId, newActive) => {
    try {
      await axios.patch(`${backend}/api/products/${productId}/active`,
        { active: newActive },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setProducts(prevProducts => prevProducts.map(product =>
        product.id === productId ? { ...product, active: newActive } : product
      ));
      ReactGA.event({ category: 'Admin', action: 'toggle_product_active' });
    } catch (error) {
      console.error('Error toggling product active status:', error);
      setError('Failed to update product active status. Please try again.');
      ReactGA.event({ category: 'Admin Toggle Product Active', action: 'exception' });
    }
  }, [backend, accessToken]);

  const handleAddProduct = useCallback(async (newProduct) => {
    if (isAddingProduct) return;
    setIsAddingProduct(true);
    try {
      setError('');
      const response = await axios.post(`${backend}/api/products`,
        newProduct,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setProducts(prevProducts => [...prevProducts, response.data]);
      setShowAddForm(false);
      ReactGA.event({ category: 'Admin', action: 'add_product' });
    } catch (error) {
      console.error('Error adding new product:', error);
      setError(error.response?.data?.message || 'Failed to add new product. Please try again.');
      ReactGA.event({ category: 'Admin Add Product', action: 'exception' });
    } finally {
      setIsAddingProduct(false);
    }
  }, [backend, accessToken, isAddingProduct]);

  if (loading) return <p>Loading products...</p>;

  return (
    <div className="admin-product-panel text-center">
      <h3>Product Management</h3>
      {error && <p className="text-danger">{error}</p>}
      <button className="btn btn-primary mb-3" onClick={() => setShowAddForm(!showAddForm)}>
        {showAddForm ? 'Cancel' : 'Add New Product'}
      </button>
      {showAddForm && <AddProductForm onAddProduct={handleAddProduct} isAddingProduct={isAddingProduct} />}
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Price</th>
            <th>Stock</th>
            <th>Active</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.id} className={product.active ? '' : 'table-secondary'}>
              <td>{product.name}</td>
              <td>
                <input
                  type="number"
                  value={product.price}
                  onChange={(e) => handlePriceChange(product.id, parseFloat(e.target.value))}
                  min="0"
                  step="0.01"
                  className="form-control"
                />
              </td>
              <td>
                <input
                  type="number"
                  value={product.stock}
                  onChange={(e) => handleStockChange(product.id, parseInt(e.target.value, 10))}
                  min="0"
                  className="form-control"
                />
              </td>
              <td>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={product.active}
                    onChange={(e) => handleActiveToggle(product.id, e.target.checked)}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminProductPanel;