import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

const OrderSummary = ({ cart, subtotal, tax, shippingCost, total, showTax, showShipping }) => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);
  return (
    <div>
      <h3>Order Summary</h3>
      <div className="cart-summary">
        {cart.map((item, index) => (
          <div key={index} className="cart-item-checkout">
            <span>{item.name}</span>
            <span>${item.price} x {item.quantity}</span>
          </div>
        ))}
      </div>
      <div className="cart-subtotal mt-3">
        <strong>Subtotal: ${subtotal}</strong>
      </div>
      {showShipping && (
        <div className="cart-shipping mt-1">
          <strong>Shipping: ${shippingCost.toFixed(2)}</strong>
        </div>
      )}
      {showTax && (
        <div className="cart-tax mt-1">
          <strong>Tax: ${(tax.amount / 100).toFixed(2)} ({(tax.rate).toFixed(2)}%)</strong>
        </div>
      )}
      {showTax && showShipping && (
        <div className="cart-total mt-1">
          <strong>Total: ${total}</strong>
        </div>
      )}
    </div>
  );
};

export default OrderSummary;