import React from 'react';
import SEO from './Seo.js';
import { SEO_DATA } from './SeoData.js';

const PrivacyPolicy = () => {
    return (
        <>
            <SEO {...SEO_DATA.privacyPolicy} />
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-10 offset-md-1">
                        <h1 className="display-4 mb-4 text-center">Privacy Policy</h1>
                        <p className="text-muted text-center mb-5"><em>Last updated: August 8th 2024</em></p>

                        <div className="privacy-policy-content">
                            {sections.map((section, index) => (
                                <section key={index} className="mb-4">
                                    <h2 className="h3 mb-3">{section.title}</h2>
                                    <div className="privacy-policy-list">
                                        {section.content}
                                    </div>
                                </section>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const sections = [
    {
        title: "1. Introduction",
        content: (
            <>
                <p className='text-left'>
                    Welcome to LED Reminder ("we," "our," or "us"). We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website <a href='https://ledreminder.com' className="text-decoration-none">ledreminder.com</a> (the "Site") and use our services.
                </p>
                <p className='text-left'>
                    Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.
                </p>
            </>
        )
    },
    {
        title: "2. Information We Collect",
        content: (
            <>
                <p className='text-left'>We collect information that you provide directly to us when you register for an account, place an order, or communicate with us. This may include:</p>
                <div className="col-md-10 offset-md-1">
                    <ul className="">
                        <li className="">Personal identifiers (e.g., name, email address)</li>
                        <li className="">Login credentials</li>
                        <li className="">Payment information</li>
                        <li className="">Shipping address</li>
                        <li className="">Any other information you choose to provide</li>
                    </ul>
                </div>
                <p className='text-left'>We also automatically collect certain information when you visit, use, or navigate the Site. This information does not reveal your specific identity but may include:</p>
                <div className="col-md-10 offset-md-1">
                    <ul className="">
                        <li className="">Device and usage information (e.g., IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Site)</li>
                        <li className="">Other technical information</li>
                    </ul>
                </div>
            </>
        )
    },
    {
        title: "3. How We Use Your Information",
        content: (
            <>
                <p className='text-left'>We use the information we collect or receive:</p>
                <div className="col-md-10 offset-md-1">
                    <ul className="">
                        <li className="">To facilitate account creation and authentication (using AWS Cognito)</li>
                        <li className="">To process and fulfill orders</li>
                        <li className="">To process payments (using Stripe)</li>
                        <li className="">To send you marketing and promotional communications (if you have opted in)</li>
                        <li className="">To respond to your inquiries and solve any potential issues you might have</li>
                        <li className="">To protect our Site from potential fraud and security risks (using reCAPTCHA v3)</li>
                        <li className="">To comply with legal obligations</li>
                    </ul>
                </div>
            </>
        )
    },
    {
        title: "4. Third-Party Services",
        content: (
            <>
                <p className='text-left'>We use the following third-party services to support our Site's functionality:</p>

                <h5 className="mt-3 text-center">Google Analytics</h5>
                <p className='text-left'>We use Google Analytics to help us understand how our customers use the Site. You can read more about how Google uses your Personal Information here: <a href="https://www.google.com/intl/en/policies/privacy/" target="_blank" rel="noopener noreferrer" className="text-decoration-none">https://www.google.com/intl/en/policies/privacy/</a></p>
                <p className='text-left'>You can also opt-out of Google Analytics here: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer" className="text-decoration-none">https://tools.google.com/dlpage/gaoptout</a></p>

                <h5 className="mt-3 text-center">AWS Cognito</h5>
                <p className='text-left'>We use AWS Cognito for user authentication. AWS may collect and store personal information as part of the authentication process. For more information, please see the AWS Privacy Notice: <a href="https://aws.amazon.com/privacy/" target="_blank" rel="noopener noreferrer" className="text-decoration-none">https://aws.amazon.com/privacy/</a></p>

                <h5 className="mt-3 text-center">Google reCAPTCHA v3</h5>
                <p className='text-left'>We use Google reCAPTCHA v3 to protect our Site from fraudulent activities. reCAPTCHA works in the background and analyzes the behavior of users on our Site. For more information about Google reCAPTCHA and its privacy policy, please visit: <a href="https://www.google.com/recaptcha/about/" target="_blank" rel="noopener noreferrer" className="text-decoration-none">https://www.google.com/recaptcha/about/</a></p>

                <h5 className="mt-3 text-center">Stripe</h5>
                <p className='text-left'>We use Stripe for payment processing. When you make a purchase, your payment information is collected and processed directly by Stripe. We do not store your full credit card details on our servers. Stripe may use your data as required for processing payments, fraud prevention, and compliance purposes. For more information, please see Stripe's Privacy Policy: <a href="https://stripe.com/privacy" target="_blank" rel="noopener noreferrer" className="text-decoration-none">https://stripe.com/privacy</a></p>
            </>
        )
    },
    {
        title: "5. Data Retention",
        content: (
            <p className='text-left'>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law.</p>
        )
    },
    {
        title: "6. Data Security",
        content: (
            <p className='text-left'>We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure.</p>
        )
    },
    {
        title: "7. Your Data Protection Rights",
        content: (
            <>
                <p className='text-left'>Depending on your location, you may have certain rights regarding your personal information, such as:</p>
                <div className="col-md-10 offset-md-1">
                    <ul className="">
                        <li className="">The right to access, update or delete your information</li>
                        <li className="">The right to rectification</li>
                        <li className="">The right to object to processing</li>
                        <li className="">The right of restriction</li>
                        <li className="">The right to data portability</li>
                        <li className="">The right to withdraw consent</li>
                    </ul>
                </div>
                <p className='text-left'>To exercise these rights, please contact us using the details provided in the "Contact Us" section below.</p>
            </>
        )
    },
    {
        title: "8. Children's Privacy",
        content: (
            <p className='text-left'>Our Site is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13.</p>
        )
    },
    {
        title: "9. International Data Transfers",
        content: (
            <p className='text-left'>Your information, including personal data, may be transferred to and maintained on computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction. If you are located outside the United States and choose to provide information to us, please note that we transfer the data, including personal data, to the United States and process it there.</p>
        )
    },
    {
        title: "10. Changes to This Privacy Policy",
        content: (
            <p className='text-left'>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.</p>
        )
    }
];

export default PrivacyPolicy;