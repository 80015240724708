import React, { useState, useEffect } from 'react';
import { useBackend } from './BackendContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

const ConfirmEmail = () => {
  const [code, setCode] = useState('');
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const backend = useBackend();
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsResendEnabled(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const handleConfirmEmail = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    ReactGA.event({ category: 'Auth', action: 'submit_confirm_email' });
    try {
      const response = await axios.post(`${backend}/api/auth/confirm-email`, {
        username,
        code,
      });
      setMessage(response.data);
      setTimeout(() => { // Use setTimeout to delay the redirect
        navigate('/login'); // Step 3: Navigate to login page
      }, 1250);
    } catch (err) {
      setError(err.response ? err.response.data : 'An error occurred');
      ReactGA.event({ category: 'Confirm Email', action: 'exception', label: err.response?.status });
    }
  };

  const handleResendCode = async () => {
    // Implement resend logic here
    setMessage('');
    setError('');

    try {
      // Assuming the backend endpoint for resending the code is '/resend-code'
      /*const response = */await axios.post(`${backend}/api/auth/resend-code`, {
      username,
    });
      setMessage('Confirmation code resent successfully.');
    } catch (err) {
      setError(err.response ? err.response.data : 'Failed to resend confirmation code.');
      ReactGA.event({ category: 'Resend Email Confirmation', action: 'exception', label: err.response?.status });
    }
  };

  return (
    <div className='container'>
      <h2>Confirm Email</h2>
      <form onSubmit={handleConfirmEmail}>
        <div className='row'>
          <label htmlFor="username">Email:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className='row'>
          <label htmlFor="code">Confirmation Code:</label>
          <input
            type="text"
            id="code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </div>
        <div className="btn-group row" role="group" aria-label="Email Actions">
          <button type="button" className="btn btn-secondary" onClick={handleResendCode} disabled={!isResendEnabled}>Resend Code</button>
          <button type="submit" className="btn btn-primary">Confirm Email</button>
        </div>
      </form>
      {message && <p>{message}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default ConfirmEmail;