import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { Helmet } from 'react-helmet-async';

const ProtectedRoute = ({ children, requireAdmin = false }) => {
    const { isAuthenticated, isAdmin, loading } = useAuth();
    const location = useLocation();

    if (loading) {
        return null;
    }

    if (!isAuthenticated) {
        return (
            <>
                <Helmet>
                    <title>Login Required</title>
                    <meta name="robots" content="noindex,nofollow" />
                    <meta name="description" content="Please login to access this page" />
                </Helmet>
                <Navigate
                    to="/login"
                    state={{ from: location }}
                    replace
                />
            </>
        );
    }

    if (requireAdmin && !isAdmin) {
        return (
            <>
                <Helmet>
                    <title>Access Denied</title>
                    <meta name="robots" content="noindex,nofollow" />
                    <meta name="description" content="Access denied" />
                </Helmet>
                <Navigate to="/404" replace />
            </>
        );
    }

    return children;
};

export default ProtectedRoute;