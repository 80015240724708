import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import axios from 'axios';
import { useCart } from './CartContext.js';
import '../Form.css';
import { useBackend } from './BackendContext.js';
import ReactGA from 'react-ga4';
import SEO from './Seo.js';

const ProductPage = () => {
  const { name } = useParams();
  const [product, setProduct] = useState(null);
  const { dispatch, state } = useCart();
  const [quantity, setQuantity] = useState(1);
  const backend = useBackend();
  const [showNotification, setShowNotification] = useState(false);
  const [stock, setStock] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${backend}/api/products/${name}`);
        setProduct(response.data);
        setStock(response.data.available_stock);
      } catch (error) {
        console.error('Error fetching product:', error);
        if (error.response?.status === 404) {
          setNotFound(true);
          ReactGA.event({
            category: 'Error',
            action: 'product_not_found',
            label: name
          });
        }
        setProduct(null);
        setStock(0);
      } finally {
        setIsLoading(false);
      }
    };

    if (name) {
      fetchProduct();
    }
  }, [name, backend]);

  const addToCart = () => {
    const cartItem = state.cart.find(item => item.id === product.id);
    const currentQuantityInCart = cartItem ? cartItem.quantity : 0;
    const availableStock = stock - currentQuantityInCart;

    if (quantity > availableStock) {
      return;
    }

    dispatch({ type: 'ADD_TO_CART', payload: { ...product, quantity, stock } });
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  // If product not found, redirect to 404 page
  if (notFound) {
    return (
      <>
        <SEO
          title="Product Not Found"
          description="The requested product could not be found."
          canonical={`/products/${name}`}
        />
        <Navigate to="/404" replace />
      </>
    );
  }

  if (isLoading) {
    return (
      <div className="container text-center mt-5">
        <div className="spinner-border" role="status">
        </div>
      </div>
    );
  }

  if (!product) {
    return null;
  }

  return (
    <div className="container">
      <SEO
        title={product.name}
        description={product.description ? product.description.substring(0, 160) : ''}
        keywords={`${product.name}, LED reminder, visual reminder, task management`}
        canonical={`/products/${name}`}
        ogImage={product.image || '/LEDReminder.png'}
      />
      <h2>{product.name}</h2>
      <div className='col'>
        <div className='row'>
          <div className="details p-3 col-md-9">
            <div className='row'>
              <div className='text-center mb-2 col-8 col-md-6 mx-auto'>
                {product.image ? (
                  <img
                    src={product.image}
                    alt={product.name}
                    className="img-fluid product-image"
                    style={{ maxWidth: '100%', height: 'auto' }}
                  />
                ) : (
                  <p>No image available</p>
                )}
              </div>
            </div>
            <div className='row'>
              <h4>Details</h4>
            </div>
            <div className='row'>
              <p className='text-left'>{product.description}</p>
            </div>
          </div>
          <div className="col-md-3 p-0">
            <div className='priceDiv p-3 border rounded'>
              <div className="d-flex flex-column align-items-center">
                <div className="mb-2 text-center">Price: $<span>{product.price}</span></div>
                <div className="mb-2 d-flex justify-content-center align-items-center w-100">
                  <label htmlFor="quantityInput" className="me-2 mb-0">Quantity:</label>
                  <input
                    type="number"
                    id="quantityInput"
                    className="form-control"
                    style={{ width: '60px' }}
                    value={quantity}
                    min="1"
                    max={stock}
                    onChange={(e) => setQuantity(Math.min(Number(e.target.value), stock))}
                  />
                </div>
                <div className="mb-2 text-center">
                  {stock > 0 ? (
                    <span className={`badge ${stock < 5 ? 'bg-warning' : 'bg-success'}`}>
                      {stock < 5 ? 'Low Stock' : 'In Stock'}: {stock} available
                    </span>
                  ) : (<div>
                    <div className='row mb-2 justify-content-center '>
                      <span className="badge bg-danger">Out of Stock</span>
                    </div>
                    <div className='row justify-content-center '>
                      <a href='/notify' className="btn btn-primary btn-sm">Notify Me</a>
                    </div>
                  </div>
                  )}
                </div>
                <button
                  className="btn btn-primary"
                  onClick={addToCart}
                  disabled={stock === 0}
                >
                  Add to Cart
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showNotification && (
        <div className="notification-popup">
          <div className="alert alert-success">Added to cart!</div>
        </div>
      )}
    </div>
  );
};

export default ProductPage;