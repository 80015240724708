import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useBackend } from './BackendContext';
import ReactGA from 'react-ga4';

const Unsubscribe = () => {
    const [status, setStatus] = useState('processing');
    const [message, setMessage] = useState('');
    const backend = useBackend();
    const { token } = useParams(); // Get token from URL parameter

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/unsubscribe" });

        const unsubscribe = async (token) => {
            try {
                const response = await axios.get(`${backend}/api/unsubscribe/${token}`);

                // Handle the response based on the status
                if (response.data.status === 'success') {
                    setStatus('success');
                    setMessage(response.data.message);
                    ReactGA.event({ category: 'Mailing List', action: 'unsubscribe_success' });
                } else {
                    setStatus('error');
                    setMessage(response.data.message);
                    ReactGA.event({ category: 'Mailing List', action: 'unsubscribe_invalid_link' });
                }
            } catch (error) {
                setStatus('error');
                setMessage(error.response?.data?.message || 'An error occurred while processing your request.');
                ReactGA.event({ category: 'Mailing List', action: 'unsubscribe_error' });
            }
        };

        if (token) {
            unsubscribe(token);
        } else {
            setStatus('error');
            setMessage('Invalid unsubscribe link.');
        }
    }, [token, backend]);

    const getAlertClass = () => {
        switch (status) {
            case 'success':
                return 'alert alert-success';
            case 'error':
                return 'alert alert-danger';
            default:
                return 'alert alert-info';
        }
    };

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8 text-center">
                    <h2 className="mb-4">Unsubscribe from Mailing List</h2>

                    {status === 'processing' && (
                        <div className="alert alert-info">
                            Processing your unsubscribe request...
                        </div>
                    )}

                    {(status === 'success' || status === 'error') && (
                        <div className={getAlertClass()}>
                            <p className="mb-0">{message}</p>
                            {status === 'success' && (
                                <p className="mt-3 mb-0">
                                    We're sorry to see you go. If you change your mind, you can always subscribe again on our website.
                                </p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Unsubscribe;