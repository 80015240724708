import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useBackend } from './BackendContext';
import ReactGA from 'react-ga4';

const ConfirmForgotPassword = () => {
    const [code, setCode] = useState('');
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const backend = useBackend();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const emailParam = queryParams.get('email');
        if (emailParam) setEmail(emailParam);
    }, [location]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');

        if (newPassword !== confirmNewPassword) {
            setError('Passwords do not match.');
            return;
        }

        if (!email){
            setError('Email is required.');
            return;
        }

        ReactGA.event({ category: 'Auth', action: 'confirm_reset_password' });

        try {
            /* const response = */await axios.post(`${backend}/api/auth/confirm-forgot-password`, {
                username: email,
                confirmationCode: code,
                password: newPassword,
            });
            setMessage('Password reset successfully.');
            setTimeout(() => navigate('/login'), 1250);
        } catch (err) {
            setError(err.response ? err.response.data : 'An error occurred during password reset.');
            ReactGA.event({ category: 'Reset Password', action: 'exception', label: err.response?.status });
        }
    };

    return (
        <div className='container'>
            <h2>Reset Password</h2>
            <form onSubmit={handleSubmit}>
                <div className='row'>
                    <label htmlFor="code">Confirmation Code:</label>
                    <input
                        type="text"
                        id="code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        required
                    />
                </div>
                <div className='row'>
                    <label htmlFor="newPassword">New Password:</label>
                    <input
                        type="password"
                        id="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                </div>
                <div className='row'>
                    <label htmlFor="confirmNewPassword">Confirm New Password:</label>
                    <input
                        type="password"
                        id="confirmNewPassword"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        required
                    />
                </div>
                <div className='row'>
                    <button className='loginButton' type="submit">Reset Password</button>
                </div>
            </form>
            {message && <p>{message}</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
};

export default ConfirmForgotPassword;