import React, { useState } from 'react';
import ReactGA from 'react-ga4';

const AddProductForm = ({ onAddProduct, isAddingProduct }) => {
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        price: '',
        stock: '',
        weight_pounds: '',
        active: true
    });
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            await onAddProduct(formData);
            // Reset form after successful submission
            setFormData({
                name: '',
                description: '',
                price: '',
                stock: '',
                weight_pounds: '',
                active: true,
                image: ''
            });
            ReactGA.event({ category: 'Admin', action: 'add_product' });
        } catch (error) {
            console.error('Error adding product:', error);
            setError('Failed to add product. Please try again.');
            ReactGA.event({ category: 'Admin Add Product', action: 'exception' });
        }
    };

    return (
        <form onSubmit={handleSubmit} className="mb-4">
            <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea className="form-control" id="description" name="description" value={formData.description} onChange={handleChange} required />
            </div>
            <div className="form-group">
                <label htmlFor="price">Price</label>
                <input type="number" className="form-control" id="price" name="price" value={formData.price} onChange={handleChange} step="0.01" required />
            </div>
            <div className="form-group">
                <label htmlFor="stock">Stock</label>
                <input type="number" className="form-control" id="stock" name="stock" value={formData.stock} onChange={handleChange} required />
            </div>
            <div className="form-group">
                <label htmlFor="weight_pounds">Weight (lbs)</label>
                <input type="number" className="form-control" id="weight_pounds" name="weight_pounds" value={formData.weight_pounds} onChange={handleChange} step="0.01" required />
            </div>
            <div className="form-group">
                <label htmlFor="image">Image URI</label>
                <input type="text" className="form-control" id="image" name="image" value={formData.image} onChange={handleChange} />
            </div>
            <div className="form-check">
                <input type="checkbox" className="form-check-input" id="active" name="active" checked={formData.active} onChange={handleChange} />
                <label className="form-check-label" htmlFor="active">Active</label>
            </div>
            {error && <div className="alert alert-danger mt-3">{error}</div>}
            <button type="submit" className="btn btn-success mt-3" disabled={isAddingProduct}>
                {isAddingProduct ? 'Adding Product...' : 'Add Product'}
            </button>
        </form>
    );
};

export default AddProductForm;