import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useBackend } from './BackendContext';
import ReactGA from 'react-ga4';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const backend = useBackend();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  const handleSubmit = async (e) => {
    ReactGA.event({ category: 'Auth', action: 'reset_password' });
    e.preventDefault();
    setMessage('');
    try {
      await axios.post(`${backend}/api/auth/forgot-password`, { username: email });
      navigate(`/confirm-forgot-password?email=${encodeURIComponent(email)}`);
    } catch (error) {
      setMessage('Error: ' + (error.response ? error.response.data.error : 'An error occurred'));
      ReactGA.event({ category: 'Submit Email Forgot Password', action: 'exception', label: error.response?.status });
    }
  };

  return (
    <div className='container'>
      <div className='col'>
        <h2>Forgot Password</h2>
        <form onSubmit={handleSubmit}>
          <div className='row'>
            <label>Email:</label>
            <input
              className='loginInput'
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className='row justify-content-center'>
            <button className='loginButton' type="submit">Send Reset Instructions</button>
          </div>
        </form>
        <div className='row justify-content-center'>
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;