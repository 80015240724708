import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import { useBackend } from './BackendContext';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { accessToken } = useAuth();
  const backend = useBackend();
  const ordersPerPage = 10;
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  const fetchOrders = useCallback(async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get(`${backend}/api/user/orders`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: {
          page: currentPage,
          limit: ordersPerPage,
          startDate,
          endDate
        }
      });
      setOrders(response.data.orders);
      setTotalPages(response.data.totalPages);
    } catch (err) {
      console.error('Error fetching orders:', err);
      setError('Failed to fetch orders. Please try again later.');
      ReactGA.event({ category: 'Fetch Orders', action: 'excepetion' });
    } finally {
      setLoading(false);
    }
  }, [backend, accessToken, currentPage, startDate, endDate]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const handlePageChange = (newPage) => {
    ReactGA.event({ category: 'Orders', action: 'orders_change_page' });
    setCurrentPage(newPage);
  };

  const handleDateFilterSubmit = (e) => {
    ReactGA.event({ category: 'Orders', action: 'orders_date_filter' });
    e.preventDefault();
    setCurrentPage(1);
    fetchOrders();
  };

  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`btn ${currentPage === i ? 'btn-primary' : 'btn-outline-primary'} mx-1`}
        >
          {i}
        </button>
      );
    }
    return pages;
  };

  return (
    <div className="orders-container text-center">
      <h3>Your Orders</h3>

      <form onSubmit={handleDateFilterSubmit} className="mb-4">
        <div className="row align-items-center">
          <div className="col">
            <input
              type="date"
              className="form-control"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              placeholder="Start Date"
            />
          </div>
          <div className="col">
            <input
              type="date"
              className="form-control"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              placeholder="End Date"
            />
          </div>
          <div className="col">
            <button type="submit" className="btn2 btn-primary d-flex justify-content-center align-items-center">Filter</button>
          </div>
        </div>
      </form>

      {loading && <p>Loading orders...</p>}
      {error && <p className="text-danger">{error}</p>}

      {!loading && !error && (
        <>
          {orders.length === 0 ? (
            <p>No orders found for the selected criteria.</p>
          ) : (
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Date</th>
                    <th>Total</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr key={order.id}>
                      <td>
                        <Link to={`/order/${order.id}`}>{order.id}</Link>
                      </td>
                      <td>{order.order_date ? format(new Date(order.order_date), 'MM/dd/yyyy') : 'N/A'}</td>
                      <td>${parseFloat(order.total).toFixed(2)}</td>
                      <td>{order.status || 'N/A'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {totalPages > 1 && (
            <div className="pagination-container mt-4">
              {renderPagination()}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Orders;