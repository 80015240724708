import React, { createContext, useContext } from 'react';

const BackendContext = createContext();

export const useBackend = () => useContext(BackendContext);

export const BackendProvider = ({ children }) => {
  const backend = process.env.REACT_APP_BACKEND_URL;

  return (
    <BackendContext.Provider value={backend}>
      {children}
    </BackendContext.Provider>
  );
};