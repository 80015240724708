import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import { useBackend } from './BackendContext';
import ReactGA from 'react-ga4';

const AdminMailingListPanel = () => {
  const [subscriberCount, setSubscriberCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [sendingNotification, setSendingNotification] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { accessToken } = useAuth();
  const backend = useBackend();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/admin/mailing-list" });

    const fetchSubscriberCount = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${backend}/api/admin/subscribers`, {
          headers: { Authorization: `Bearer ${accessToken}` }
        });
        setSubscriberCount(response.data.length);
      } catch (err) {
        console.error('Error fetching subscribers:', err);
        setError('Failed to load subscriber data');
        ReactGA.event({ category: 'Admin Mailing List', action: 'fetch_error' });
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriberCount();
  }, [backend, accessToken]); // Added proper dependencies

  const handleSendNotification = async () => {
    setSendingNotification(true);
    setError('');
    setSuccessMessage('');
    setShowConfirmation(false);

    try {
      const productUrl = `${window.location.origin}/products/LED%20Reminder`;

      await axios.post(
        `${backend}/api/admin/send-restock-notification`,
        {
          productUrl: productUrl
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      );

      setSuccessMessage('Restock notification sent successfully');
      ReactGA.event({ category: 'Admin Mailing List', action: 'send_notification_success' });
    } catch (err) {
      console.error('Error sending notification:', err);
      setError('Failed to send restock notification');
      ReactGA.event({ category: 'Admin Mailing List', action: 'send_notification_error' });
    } finally {
      setSendingNotification(false);
    }
  };

  const ConfirmationDialog = () => (
    <div className="modal fade show" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }} tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Confirm Restock Notification</h5>
            <button type="button" className="btn-close" onClick={() => setShowConfirmation(false)}></button>
          </div>
          <div className="modal-body">
            <p>Are you sure you want to send a restock notification to {subscriberCount} subscriber{subscriberCount !== 1 ? 's' : ''}?</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => setShowConfirmation(false)}>Cancel</button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSendNotification}
              disabled={sendingNotification}
            >
              {sendingNotification ? 'Sending...' : 'Send Notification'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  if (loading) return <div className="text-center mt-4">Loading subscriber data...</div>;

  return (
    <div className="admin-mailing-list-panel">
      <div className="card">
        <div className="card-body text-center">
          <h3 className="card-title mb-4">Mailing List Management</h3>

          <div className="mb-4">
            <h5>Active Subscribers</h5>
            <p className="display-4 mb-4">{subscriberCount}</p>
          </div>

          <button
            className="btn btn-primary btn-lg"
            onClick={() => setShowConfirmation(true)}
            disabled={sendingNotification || subscriberCount === 0}
          >
            Send Restock Notification
          </button>

          {error && <div className="alert alert-danger mt-4">{error}</div>}
          {successMessage && <div className="alert alert-success mt-4">{successMessage}</div>}
        </div>
      </div>

      {showConfirmation && <ConfirmationDialog />}
    </div>
  );
};

export default AdminMailingListPanel;