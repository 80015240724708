import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../Form.css';
import { useAuth } from './AuthContext.js';
import { useNavigate, Link, Navigate, useLocation } from 'react-router-dom';
import { useBackend } from './BackendContext.js';
import ReactGA from 'react-ga4';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function Login() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const backend = useBackend();

  const { login, isAuthenticated } = useAuth();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  useEffect(() => {
    let navigationTimer;

    if (message === 'Login successful') {
      navigationTimer = setTimeout(() => {
        const previousPath = location.state?.from || '/';
        navigate(previousPath);
      }, 1250);
    }

    return () => {
      if (navigationTimer) {
        clearTimeout(navigationTimer);
      }
    };
  }, [message, navigate, location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');

    if (!executeRecaptcha) {
      setIsLoading(false);
      return;
    }

    try {
      const token = await executeRecaptcha('login');
      const response = await axios.post(`${backend}/api/auth/login`, {
        username: email,
        password,
        recaptchaToken: token
      });
      login(response.data);
      ReactGA.event({ category: 'Auth', action: 'login' });
      setMessage('Login successful');
    } catch (error) {
      ReactGA.event({ category: 'Auth', action: 'exception', label: error.response?.status });
      if (error.response) {
        setMessage(error.response.data);
      } else {
        setMessage('Error: ', error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (isAuthenticated) {
    const previousPath = location.state?.from || '/';
    return <Navigate to={previousPath} replace />;
  }

  return (
    <div className='container'>
      <div className='col'>
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className='row'>
            <label>Email:</label>
            <input className='loginInput' type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </div>
          <div className='row'>
            <label>Password:</label>
            <input className='loginInput' type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
          </div>
          <div className='row justify-content-center'>
            <button className='loginButton' type="submit" disabled={isLoading}>
              {isLoading ? 'Logging in...' : 'Login'}
            </button>
          </div>
        </form>
        <div className='row justify-content-center'>
          <p>{message}</p>
        </div>
        <div className='row justify-content-center'>
          <Link to='/Forgot-Password'>Forgot Password?</Link>
        </div>
      </div>
    </div>
  );
}

export default Login;