import React, { useEffect, useState, useCallback } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { useBackend } from './BackendContext';
import axios from 'axios';
import ReactGA from 'react-ga4';

const CheckoutSuccess = () => {
  const [searchParams] = useSearchParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { accessToken } = useAuth();
  const backend = useBackend();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  const fetchOrderDetails = useCallback(async (orderId) => {
    try {
      const response = await axios.get(`${backend}/api/orders/${orderId}`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data) {
        setOrderDetails(response.data);
      } else {
        setError('Order details not found');
      }
    } catch (error) {
      console.error('Error fetching order details:', error);
      setError('Failed to load order details');
      ReactGA.event({ category: 'Fetch Order Details', action: 'exception' });
    } finally {
      setLoading(false);
    }
  }, [accessToken, backend]);

  useEffect(() => {
    const orderId = searchParams.get('order_id');

    if (orderId) {
      fetchOrderDetails(orderId);
    } else {
      setError('No order details found');
      setLoading(false);
    }
  }, [searchParams, fetchOrderDetails]);

  if (loading) return <div>Loading order details...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="checkout-success container mt-5 text-center">
      <h2>Thank You for Your Purchase!</h2>
      {orderDetails && (
        <div>
          <div className='my-3'>
            <Link to={`/order/${orderDetails.id}`}>Order ID: {orderDetails.id}</Link>
          </div>
          <p>Subtotal: ${parseFloat(orderDetails.subtotal).toFixed(2)}</p>
          <p>Shipping: ${parseFloat(orderDetails.shipping_cost).toFixed(2)}</p>
          <p>Tax: ${parseFloat(orderDetails.tax).toFixed(2)}</p>
          <p>Total Amount: ${(parseFloat(orderDetails.subtotal) + parseFloat(orderDetails.shipping_cost) + parseFloat(orderDetails.tax)).toFixed(2)}</p>
          <p>Status: {orderDetails.status}</p>
          {/* Add more order details as needed */}
        </div>
      )}
      <Link to="/" className="btn btn-primary mt-3">Continue Shopping</Link>
    </div>
  );
};

export default CheckoutSuccess;