import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './AuthContext';
import { useBackend } from './BackendContext';
import { format } from 'date-fns';
import ReactGA from 'react-ga4';

const AdminOrderDetail = () => {
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [updatedOrder, setUpdatedOrder] = useState({});
    const { id } = useParams();
    const { accessToken, isAuthenticated, isAdmin } = useAuth();
    const backend = useBackend();
    const navigate = useNavigate();
    const location = useLocation();

    const fetchOrderDetail = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${backend}/api/admin/order/${id}`, {
                headers: { Authorization: `Bearer ${accessToken}` }
            });
            setOrder(response.data);
            setUpdatedOrder(response.data);
        } catch (err) {
            if (err.response?.status === 403) {
                setError('You do not have permission to view this order.');
                setTimeout(() => navigate('/admin/orders'), 3000);
            } else {
                setError('Failed to fetch order details. Please try again later.');
            }
            ReactGA.event({ category: 'Admin Fetch Order Detail', action: 'exception' });
        } finally {
            setLoading(false);
        }
    }, [accessToken, backend, id, navigate]);

    useEffect(() => {
        // Check authentication and admin status
        if (!isAuthenticated) {
            navigate('/login', {
                state: { from: location.pathname }
            });
            return;
        }

        if (!isAdmin) {
            navigate('/');
            return;
        }

        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        fetchOrderDetail();
    }, [fetchOrderDetail, isAuthenticated, isAdmin, navigate, location.pathname]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUpdatedOrder(prev => ({ ...prev, [name]: value }));
    };

    const handleStatusChange = (e) => {
        const newStatus = e.target.value;
        setUpdatedOrder(prev => ({ ...prev, status: newStatus }));
    };

    const handleSave = async () => {
        try {
            const response = await axios.patch(
                `${backend}/api/admin/order/${id}`,
                {
                    status: updatedOrder.status,
                    address: updatedOrder.address,
                    tracking_number: updatedOrder.tracking_number
                },
                { headers: { Authorization: `Bearer ${accessToken}` } }
            );

            // Merge the response data with existing order data to preserve items
            setOrder(prevOrder => ({
                ...prevOrder,
                status: response.data.status,
                address: response.data.address,
                tracking_number: response.data.tracking_number
            }));

            // Update the updatedOrder state to match
            setUpdatedOrder(prevOrder => ({
                ...prevOrder,
                status: response.data.status,
                address: response.data.address,
                tracking_number: response.data.tracking_number
            }));

            setEditMode(false);
            ReactGA.event({ category: 'Admin Order', action: 'save_order_changes' });
        } catch (err) {
            if (err.response?.status === 403) {
                setError('You do not have permission to modify this order.');
            } else {
                setError('Failed to save changes. Please try again.');
            }
            ReactGA.event({ category: 'Admin Save Order Changes', action: 'exception' });
        }
    };

    if (loading) return <p>Loading order details...</p>;
    if (error) return <p className="text-danger">{error}</p>;
    if (!order) return <p>Order not found</p>;

    const getStripeLink = () => {
        if (!order.payment_intent_id) return null;
        return `https://dashboard.stripe.com/payments/${order.payment_intent_id}`;
    };

    return (
        <div className="admin-order-detail container my-4">
            <h2>Order Details (Admin View)</h2>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <p><strong>Order ID:</strong> {order.id}</p>
                            <p><strong>Order Date:</strong> {format(new Date(order.order_date), 'MM/dd/yyyy')}</p>
                            <p><strong>Customer Name:</strong> {order.name}</p>
                            <p><strong>Customer Email:</strong> {order.user_email}</p>
                            {order.payment_intent_id && (
                                <p>
                                    <strong>Payment:</strong>{' '}
                                    <a
                                        href={getStripeLink()}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-primary"
                                    >
                                        View Stripe Transaction
                                    </a>
                                </p>
                            )}
                        </div>
                        <div className="col-md-6">
                            <p>
                                <strong>Status:</strong>
                                {editMode ? (
                                    <select
                                        className="form-control d-inline-block w-auto ml-2"
                                        value={updatedOrder.status}
                                        onChange={handleStatusChange}
                                    >
                                        <option value="pending">Pending</option>
                                        <option value="processing">Processing</option>
                                        <option value="shipped">Shipped</option>
                                        <option value="delivered">Delivered</option>
                                        <option value="refunded">Refunded</option>
                                        <option value="cancelled">Cancelled</option>
                                    </select>
                                ) : (
                                    <span className="ml-2">{order.status}</span>
                                )}
                            </p>
                            <p>
                                <strong>Tracking Number:</strong>
                                {editMode ? (
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="tracking_number"
                                        value={updatedOrder.tracking_number || ''}
                                        onChange={handleInputChange}
                                    />
                                ) : (
                                    <span className="ml-2">{order.tracking_number || 'Not available'}</span>
                                )}
                            </p>
                        </div>
                    </div>

                    <h4 className="mt-4">Shipping Address</h4>
                    {editMode ? (
                        <textarea
                            className="form-control"
                            name="address"
                            value={updatedOrder.address || ''}
                            onChange={handleInputChange}
                            rows="3"
                        ></textarea>
                    ) : (
                        <p>{order.formatted_address}</p>
                    )}

                    <h4 className="mt-4">Order Items</h4>
                    {order.items && order.items.length > 0 ? (
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>Subtotal</th>
                                </tr>
                            </thead>
                            <tbody>
                                {order.items.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.product_name}</td>
                                        <td>{item.quantity}</td>
                                        <td>${parseFloat(item.price).toFixed(2)}</td>
                                        <td>${(parseFloat(item.price) * parseInt(item.quantity)).toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>No items found for this order.</p>
                    )}

                    <div className="row justify-content-end mt-4">
                        <div className="col-md-5">
                            <table className="table table-bordered">
                                <tbody>
                                    <tr>
                                        <th>Subtotal:</th>
                                        <td>${parseFloat(order.subtotal).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <th>Shipping Cost:</th>
                                        <td>${parseFloat(order.shipping_cost).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <th>Tax:</th>
                                        <td>${parseFloat(order.tax).toFixed(2)}</td>
                                    </tr>
                                    <tr className="table-active">
                                        <th>Total:</th>
                                        <td><strong>${(parseFloat(order.subtotal) + parseFloat(order.shipping_cost) + parseFloat(order.tax)).toFixed(2)}</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="mt-4">
                        {editMode ? (
                            <>
                                <button className="btn btn-primary mr-2" onClick={handleSave}>Save Changes</button>
                                <button className="btn btn-secondary" onClick={() => setEditMode(false)}>Cancel</button>
                            </>
                        ) : (
                            <button className="btn btn-primary" onClick={() => setEditMode(true)}>Edit Order</button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminOrderDetail;