import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './AuthContext';
import { useBackend } from './BackendContext';
import { format } from 'date-fns';
import ReactGA from 'react-ga4';

const OrderDetail = () => {
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { id } = useParams();
  const { accessToken, isAuthenticated } = useAuth();
  const backend = useBackend();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login', {
        state: { from: location.pathname }
      });
      return;
    }

    const fetchOrderDetail = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${backend}/api/orders/${id}`, {
          headers: { Authorization: `Bearer ${accessToken}` }
        });
        setOrder(response.data);
      } catch (err) {
        ReactGA.event({ category: 'Fetch Order Details', action: 'exception', label: err.response?.status });
        console.error('Error fetching order details:', err);
        if (err.response && err.response.status === 403) {
          setError('You are not authorized to view this order.');
          setTimeout(() => navigate('/orders'), 3000); // Redirect to orders page after 3 seconds
        } else {
          setError('Failed to fetch order details. Please try again later.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetail();
  }, [id, accessToken, backend, navigate, isAuthenticated, location.pathname]);

  const handlePrint = () => {
    ReactGA.event({ category: 'Print', action: 'print_order' });
    window.print();
  };

  if (loading) return <p>Loading order details...</p>;
  if (error) return <p className="text-danger">{error}</p>;
  if (!order) return <p>Order not found</p>;

  return (
    <div className="order-detail-container container my-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Order Details</h2>
        <div>
          <button onClick={handlePrint} className="btn btn-primary print-button">Print Order</button>
        </div>
      </div>

      <table className="table table-bordered">
        <tbody>
          <tr>
            <th>Order ID:</th>
            <td>{order.id}</td>
            <th>Order Date:</th>
            <td>{format(new Date(order.order_date), 'MM/dd/yyyy')}</td>
          </tr>
          <tr>
            <th>Status:</th>
            <td>{order.status}</td>
            <th>Recipient Name:</th>
            <td>{order.name}</td>
          </tr>
          <tr>
            <th>Shipping Address:</th>
            <td colSpan="3">{order.formatted_address}</td>
          </tr>
          <tr>
            <th>Tracking Number:</th>
            <td colSpan="3">{order.tracking_number || 'Tracking will be provided when label is created'}</td>
          </tr>
        </tbody>
      </table>

      <h3 className="mt-4">Order Items</h3>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Product</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Subtotal</th>
          </tr>
        </thead>
        <tbody>
          {order.items.map((item, index) => (
            <tr key={index}>
              <td>{item.product_name || 'N/A'}</td>
              <td>{item.quantity}</td>
              <td>${parseFloat(item.price).toFixed(2)}</td>
              <td>${(parseFloat(item.price) * parseInt(item.quantity)).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="row justify-content-end mt-4">
        <div className="col-md-5">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th>Subtotal:</th>
                <td>${parseFloat(order.subtotal).toFixed(2)}</td>
              </tr>
              <tr>
                <th>Shipping Cost:</th>
                <td>${parseFloat(order.shipping_cost).toFixed(2)}</td>
              </tr>
              <tr>
                <th>Tax:</th>
                <td>${parseFloat(order.tax).toFixed(2)}</td>
              </tr>
              <tr className="table-active">
                <th>Total:</th>
                <td><strong>${(parseFloat(order.subtotal) + parseFloat(order.shipping_cost) + parseFloat(order.tax)).toFixed(2)}</strong></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="mt-4 text-center">
        <p>If you have any concerns about your order, please contact our support team at:</p>
        <p><strong>support@ledreminder.com</strong></p>
      </div>
    </div>
  );
};

export default OrderDetail;