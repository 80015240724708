import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="mt-4 container">
            <div className="d-flex justify-content-center text-center">
                <Link to="https://github.com/NicoleFaye/LED-Reminder" className="text-muted mx-2">Github</Link>
                <Link to="/privacy-policy" className="text-muted mx-2">Privacy Policy</Link>
                <Link to="/terms-of-service" className="text-muted mx-2">Terms of Service</Link>
                <Link to="/contact" className="text-muted mx-2">Contact</Link>
            </div>
        </footer>
    );
};

export default Footer;