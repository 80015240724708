import React, { useEffect, useState } from 'react';
import { useNavigate} from 'react-router-dom';
import ReactGA from 'react-ga4';
import axios from 'axios';
import { useAuth } from './AuthContext';
import { useBackend } from './BackendContext.js';

const ProfileDetails = () => {
    const [user, setUser] = useState(null);
    const backend = useBackend();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { accessToken, isAuthenticated } = useAuth();
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
            return;
        }

        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${backend}/api/auth/user`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                setUser(response.data.data);
            } catch (error) {
                console.error('Failed to fetch user data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();

    }, [accessToken, backend, navigate, isAuthenticated]);

    const findUserAttribute = (name) => {
        if (!user || !user.UserAttributes) return 'Not available';
        const attribute = user.UserAttributes.find(attr => attr.Name === name);
        return attribute ? attribute.Value : 'Not available';
    };
    if (loading) {
        return <p>Loading...</p>;
    }
    return (
        <div>
            <h3 className='text-center'>Profile Information</h3>
            <p><strong>Email:</strong> {findUserAttribute('email')}</p>
            {/* Add more user information here */}
        </div>
    );
}

export default ProfileDetails;