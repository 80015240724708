import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Register from './components/Register';
import Login from './components/Login';
import Cart from './components/Cart';
import Checkout from './components/Checkout';
import CheckoutSuccess from './components/CheckoutSuccess';
import Home from './components/Home';
import ProductPage from './components/ProductPage';
import Profile from './components/Profile';
import OrderDetail from './components/OrderDetail';
import { CartProvider } from './components/CartContext.js';
import { AuthProvider, useAuth } from './components/AuthContext.js';
import { BackendProvider } from './components/BackendContext.js';
import ConfirmEmail from './components/ConfirmEmail.js';
import ForgotPassword from './components/ForgotPassword.js';
import ConfirmForgotPassword from './components/ConfirmForgotPassword.js';
import Navbar from './components/NavBar.js';
import ReactGA from 'react-ga4';
import AdminPanel from './components/AdminPanel';
import AdminOrderDetail from './components/AdminOrderDetail';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy.js';
import TermsOfService from './components/TermsOfService.js';
import Contact from './components/Contact.js';
import MailingListSignup from './components/MailingListSignup.js';
import MailingListUnsubscribe from './components/MailingListUnsubscribe.js';
import { HelmetProvider } from 'react-helmet-async';
import NotFound from './components/NotFound.js';
import ProtectedRoute from './components/ProtectedRoute';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function AppContent() {
  const { isAdmin } = useAuth();

  const measurementId = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;
  ReactGA.initialize(measurementId);

  return (
    <div className="d-flex flex-column min-vh-100">
      <div className="flex-grow-1">
        <Navbar />
        <div className="main-content">
          <ScrollToTop />
          <Routes>
            {/* Public routes */}
            <Route path="/" element={<Home />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/products/:name" element={<ProductPage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/confirm-email" element={<ConfirmEmail />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/confirm-forgot-password" element={<ConfirmForgotPassword />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/notify" element={<MailingListSignup />} />
            <Route path="/unsubscribe/:token" element={<MailingListUnsubscribe />} />

            {/* Protected routes */}
            <Route
              path="/profile/*"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/order/:id"
              element={
                <ProtectedRoute>
                  <OrderDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/checkout"
              element={
                <ProtectedRoute>
                  <Checkout />
                </ProtectedRoute>
              }
            />
            <Route
              path="/checkout/success"
              element={
                <ProtectedRoute>
                  <CheckoutSuccess />
                </ProtectedRoute>
              }
            />

            {/* Admin routes */}
            <Route
              path="/admin/*"
              element={
                <ProtectedRoute requireAdmin>
                  {isAdmin ? <AdminPanel /> : <Navigate to="/404" replace />}
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/order/:id"
              element={
                <ProtectedRoute requireAdmin>
                  {isAdmin ? <AdminOrderDetail /> : <Navigate to="/404" replace />}
                </ProtectedRoute>
              }
            />

            {/* 404 handling */}
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/404" replace />} />
          </Routes>
        </div>
      </div>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <HelmetProvider>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        scriptProps={{
          async: false,
          defer: false,
          appendTo: 'head',
          nonce: undefined,
        }}
      >
        <BackendProvider>
          <AuthProvider>
            <CartProvider>
              <Elements stripe={stripePromise}>
                <Router>
                  <AppContent />
                </Router>
              </Elements>
            </CartProvider>
          </AuthProvider>
        </BackendProvider>
      </GoogleReCaptchaProvider>
    </HelmetProvider>
  );
}

export default App;