import React, { useState } from 'react';
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useBackend } from './BackendContext';
import ReactGA from 'react-ga4';
import SEO from './Seo.js';
import { SEO_DATA } from './SeoData.js';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitMessage, setSubmitMessage] = useState('');
    const [submitError, setSubmitError] = useState('');
    const { executeRecaptcha } = useGoogleReCaptcha();
    const backend = useBackend();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validateForm = () => {
        if (!formData.name.trim()) return "Name is required";
        if (!formData.email.trim()) return "Email is required";
        if (!/\S+@\S+\.\S+/.test(formData.email)) return "Email is invalid";
        if (!formData.subject.trim()) return "Subject is required";
        if (!formData.message.trim()) return "Message is required";
        return null;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitMessage('');
        setSubmitError('');

        const validationError = validateForm();
        if (validationError) {
            setSubmitError(validationError);
            return;
        }

        if (!executeRecaptcha) {
            setSubmitError("reCAPTCHA not available");
            return;
        }

        setIsSubmitting(true);

        try {
            const recaptchaToken = await executeRecaptcha('contact_form');
            //const response = await axios.post(`${backend}/api/contact`, {
            await axios.post(`${backend}/api/contact`, {
                ...formData,
                recaptchaToken
            });

            setSubmitMessage('Your message has been sent successfully!');
            setFormData({ name: '', email: '', subject: '', message: '' });
            ReactGA.event({ category: 'Contact', action: 'form_submission' });
        } catch (error) {
            console.error('Error submitting form:', error);
            setSubmitError('Failed to send message. Please try again later.');
            ReactGA.event({ category: 'Contact', action: 'form_submission_error' });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <SEO {...SEO_DATA.contact} />
            <div className="container">
                <h2 className="mb-4">Contact Us</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="subject" className="form-label">Subject</label>
                        <input
                            type="text"
                            className="form-control"
                            id="subject"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="message" className="form-label">Message</label>
                        <textarea
                            className="form-control"
                            id="message"
                            name="message"
                            rows="5"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        ></textarea>
                    </div>
                    <button type="submit" className="btn btn-primary m-0" disabled={isSubmitting}>
                        {isSubmitting ? 'Sending...' : 'Send Message'}
                    </button>
                </form>
                {submitMessage && <div className="alert alert-success mt-3">{submitMessage}</div>}
                {submitError && <div className="alert alert-danger mt-3">{submitError}</div>}
            </div>
        </>
    );
};

export default Contact;