import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import SEO from './Seo';

const NotFound = () => {
    useEffect(() => {
        // Track 404 page views
        ReactGA.send({
            hitType: "pageview",
            page: "/404",
            title: "404 Not Found"
        });
    }, []);

    return (
        <>
            <SEO
                title="Page Not Found"
                description="The page you're looking for could not be found."
                canonical="/404"
            />
            <div className="container mt-5 text-center">
                <div className="mx-auto max-w-lg">
                    <h1 className="text-6xl font-bold mb-4">404</h1>
                    <h2 className="text-2xl font-semibold mb-4">Page Not Found</h2>
                    <p className="text-gray-600 mb-8">
                        The page you're looking for doesn't exist or has been moved.
                    </p>
                    <Link
                        to="/"
                        className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-6 rounded transition-colors duration-200"
                    >
                        Return Home
                    </Link>
                </div>
            </div>
        </>
    );
};

export default NotFound;