import React, { useState } from 'react';
import axios from 'axios';
import { useBackend } from './BackendContext';
import ReactGA from 'react-ga4';
import SEO from './Seo.js';
import { SEO_DATA } from './SeoData.js';

const MailingListSignup = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const backend = useBackend();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setMessage('');
        setMessageType('');

        try {
            const response = await axios.post(`${backend}/api/subscribe`, { email });

            // Handle the status from the response
            if (response.data.status === 'success') {
                setMessage(response.data.message);
                setMessageType('success');
                setEmail('');
                ReactGA.event({ category: 'Mailing List', action: 'signup_success' });
            } else if (response.data.status === 'warning') {
                setMessage(response.data.message);
                setMessageType('warning');
                ReactGA.event({ category: 'Mailing List', action: 'signup_warning' });
            } else if (response.data.status === 'error') {
                setMessage(response.data.message);
                setMessageType('error');
                ReactGA.event({ category: 'Mailing List', action: 'signup_error' });
            }
        } catch (error) {
            // Handle different types of errors
            if (error.response?.data?.message) {
                setMessage(error.response.data.message);
            } else {
                setMessage('Error signing up. Please try again.');
            }
            setMessageType('error');
            ReactGA.event({ category: 'Mailing List', action: 'signup_error' });
        } finally {
            setIsLoading(false);
        }
    };

    const getAlertClass = () => {
        switch (messageType) {
            case 'success':
                return 'alert alert-success';
            case 'warning':
                return 'alert alert-warning';
            case 'error':
                return 'alert alert-danger';
            default:
                return 'alert alert-info';
        }
    };

    return (
        <>
            <SEO {...SEO_DATA.mailingList} />
            <div className="container text-center">
                <h2>Get Notified When We Restock</h2>
                <form onSubmit={handleSubmit} className="mt-4">
                    <div className="form-group">
                        <label htmlFor="email">Email address</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            placeholder="Enter your email"
                        />
                    </div>
                    <button type="submit" className="btn btn-primary mt-3" disabled={isLoading}>
                        {isLoading ? 'Signing up...' : 'Sign Up'}
                    </button>
                </form>
                {message && (
                    <div className={`${getAlertClass()} mt-3`} role="alert">
                        {message}
                    </div>
                )}
                <div className="mt-4 text-muted col-md-6 offset-md-3 text-left small">
                    <ul className="list-unstyled">
                        <li>• Your email is only used for restock notifications</li>
                        <li>• We never share your information with third parties</li>
                        <li>• You can unsubscribe at any time</li>
                        <li>• Unsubscribed emails are permanently anonymized</li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default MailingListSignup;