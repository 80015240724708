// AddressForm.js
import React from 'react';
import { AddressElement, useElements } from '@stripe/react-stripe-js';
import ReactGA from 'react-ga4';

const AddressForm = ({ onAddressSubmit }) => {
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();


    ReactGA.event({ category: 'Checkout', action: 'submit_checkout_address' });
    if (!elements) {
      console.error('Stripe Elements has not loaded yet.');
      return;
    }

    const addressElement = elements.getElement('address');
    const { complete, value: addressValues } = await addressElement.getValue();

    if (complete) {
      onAddressSubmit(addressValues);
    } else {
      console.error('Please fill out all required address fields.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3>Shipping Information</h3>
      <AddressElement
        options={{
          mode: 'shipping',
          allowedCountries: ['US', 'CA'],
          autocomplete: {
            mode: 'google_maps_api',
            apiKey: 'AIzaSyDCk101cWGeh1gdD4DKq_7YpojZ2tywtuU'
          }
        }} />
      <button type="submit" className="btn btn-primary mt-3">
        Next
      </button>
    </form>
  );
};

export default AddressForm;