import React, { useState, useEffect, useRef } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import OrderSummary from './OrderSummary';
import { useCart } from './CartContext';
import { useAuth } from './AuthContext';
import { useBackend } from './BackendContext';
import axios from 'axios';
import ReactGA from 'react-ga4';
import SEO from './Seo.js';
import { SEO_DATA } from './SeoData.js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Checkout = () => {
  const [step, setStep] = useState('address');
  const [clientSecret, setClientSecret] = useState('');
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [tax, setTax] = useState({ amount: 0, rate: 0 });
  const [shippingCost, setShippingCost] = useState(0);
  const [error, setError] = useState(null);
  const isNavigatingToSuccess = useRef(false);
  const navigate = useNavigate();

  const { state, dispatch } = useCart();
  const { accessToken } = useAuth();
  const backend = useBackend();

  // Handle regular navigation away
  useEffect(() => {
    const cancelPaymentIntent = async () => {
      if (paymentIntentId && step === 'payment' && !isNavigatingToSuccess.current) {
        try {
          await axios.post(
            `${backend}/api/payments/cancel-intent`,
            { paymentIntentId },
            { headers: { Authorization: `Bearer ${accessToken}` } }
          );
          console.log('Payment intent cancelled successfully');
        } catch (error) {
          console.error('Error cancelling payment intent:', error);
        }
      }
    };

    return () => {
      cancelPaymentIntent();
    };
  }, [paymentIntentId, step, backend, accessToken]);

  // Handle tab/browser closing
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (paymentIntentId && step === 'payment' && !isNavigatingToSuccess.current) {
        // Use sendBeacon for more reliable delivery when page is being unloaded
        const data = JSON.stringify({ paymentIntentId });
        navigator.sendBeacon(
          `${backend}/api/payments/cancel-intent-beacon`,
          new Blob([data], { type: 'application/json' })
        );

        // Standard beforeunload dialog
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [paymentIntentId, step, backend]);

  // Redirect if cart is empty
  useEffect(() => {
    if (state.cart.length === 0) {
      navigate('/cart');
    }
  }, [state.cart.length, navigate]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  const calculateSubtotal = () => {
    return state.cart.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };

  const calculateTotal = () => {
    const subtotal = parseFloat(calculateSubtotal());
    const taxAmount = parseFloat(tax.amount / 100) || 0;
    return (subtotal + taxAmount + shippingCost).toFixed(2);
  };

  const handleAddressSubmit = async (address) => {
    try {
      const response = await axios.post(
        `${backend}/api/payments/create-intent`,
        {
          currency: 'usd',
          shippingAddress: address,
          items: state.cart.map(item => ({
            product_id: item.id,
            quantity: item.quantity
          }))
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setClientSecret(response.data.paymentIntent.client_secret);
      setPaymentIntentId(response.data.paymentIntent.id);
      setTax({
        amount: parseFloat(response.data.taxCalculation.tax_amount_exclusive),
        rate: parseFloat(response.data.taxCalculation.tax_breakdown[0].tax_rate_details.percentage_decimal)
      });
      setShippingCost(parseFloat(response.data.shippingCost));
      setStep('payment');
    } catch (error) {
      if (error.response && error.response.data.type === 'InsufficientStockError') {
        const { productName, availableStock, requestedQuantity } = error.response.data;
        setError(`Insufficient stock for ${productName}. Available: ${availableStock}, Requested: ${requestedQuantity}`);
        ReactGA.event({
          category: 'Create Payment Intent',
          action: 'exception',
          label: 'InsufficientStockError'
        });
      } else {
        setError('Failed to set up payment. Please try again or contact support.');
        ReactGA.event({
          category: 'Create Payment Intent',
          action: 'exception',
          label: error.message
        });
      }
    }
  };

  const createOrder = async (paymentIntentId) => {
    try {
      const response = await axios.post(`${backend}/api/orders`, { paymentIntentId }, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });

      isNavigatingToSuccess.current = true; // Set ref before navigation
      dispatch({ type: 'CLEAR_CART' });
      return response.data.orderId;
    } catch (error) {
      ReactGA.event({ category: 'Create Order', action: 'exception', label: error.response?.data?.error || 'Unknown error' });

      if (error.response?.data?.error === 'InsufficientStockError') {
        const { productId, requestedQuantity, availableStock } = error.response.data.details;
        const product = state.cart.find(item => item.id === productId);
        throw new Error(`Insufficient stock for ${product ? product.name : 'a product'}. Available: ${availableStock}, Requested: ${requestedQuantity}`);
      } else {
        throw new Error('Failed to create order. Please try again or contact support.');
      }
    }
  };

  return (
    <>
      <SEO {...SEO_DATA.checkout} />
      <div className="container">
        <h2>Checkout</h2>
        <div className="row">
          <div className="col-md-6">
            <OrderSummary
              cart={state.cart}
              subtotal={calculateSubtotal()}
              tax={tax}
              shippingCost={shippingCost}
              total={calculateTotal()}
              showTax={step === 'payment'}
              showShipping={step === 'payment'}
            />
          </div>
          <div className="col-md-6">
            {error && <div className="alert alert-danger mt-3">{error}</div>}
            {step === 'address' && (
              <AddressForm
                onAddressSubmit={handleAddressSubmit}
                cartItems={state.cart}
              />
            )}
            {step === 'payment' && clientSecret && (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <PaymentForm
                  onOrderCreated={createOrder}
                />
              </Elements>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;