export const SEO_DATA = {
    cart: {
        title: 'Shopping Cart',
        description: 'Review and manage your LED Reminder selections before checkout. Secure shopping experience with easy navigation and clear pricing.',
        keywords: 'shopping cart, LED reminder, checkout, purchase, buy LED reminder',
        canonical: '/cart'
    },
    checkout: {
        title: 'Secure Checkout',
        description: 'Complete your LED Reminder purchase securely. Fast and safe payment processing with multiple payment options available.',
        keywords: 'checkout, secure payment, buy LED reminder, payment processing',
        canonical: '/checkout'
    },
    profile: {
        title: 'My Account',
        description: 'Manage your LED Reminder account, view order history, and update your preferences.',
        keywords: 'account management, order history, user profile, LED reminder account',
        canonical: '/profile'
    },
    privacyPolicy: {
        title: 'Privacy Policy',
        description: 'Learn about how LED Reminder protects and handles your personal information.',
        keywords: 'privacy policy, data protection, personal information, privacy terms',
        canonical: '/privacy-policy'
    },
    termsOfService: {
        title: 'Terms of Service',
        description: 'Read our terms of service and understand your rights and responsibilities when using LED Reminder.',
        keywords: 'terms of service, legal terms, user agreement, conditions of use',
        canonical: '/terms-of-service'
    },
    contact: {
        title: 'Contact Us',
        description: 'Get in touch with LED Reminder support. We\'re here to help with your questions about our visual reminder system and provide technical assistance.',
        keywords: 'contact LED Reminder, customer support, technical help, LED reminder assistance, customer service',
        canonical: '/contact'
    },
    notify: {
        title: 'Restock Notification',
        description: 'Sign up to be notified when LED Reminder is back in stock. Don\'t miss out on our innovative visual reminder system that helps you stay organized.',
        keywords: 'LED reminder notification, back in stock alert, product availability, LED reminder waitlist, stock notification',
        canonical: '/notify'
    }
};